import * as THREE from "three";
import { lerp } from "./utils";

const textItems = ["$iain exists at 0xc05c01bbd9a41f2f089910a996655ebef0770479", "$iain has no monetary value", "the only way to receive $iain is to ask", "$iain may include alpha access, it may not"];

export class PackedPlanes {
  constructor(sceneManager) {
    this.sceneManager = sceneManager;
    this.textures = [];
    this.uAttractor = new THREE.Uniform(new THREE.Vector3(0, 0, 0));
    this.uvOffset = new THREE.Uniform(new THREE.Vector2(0, 0));
    this.uProgress = new THREE.Uniform(0.5);
    this.setAttractor(new THREE.Vector3(0.2, 0.3, 0.3))
    this.targetProgress = 0;

    const textCanvas = document.createElement("canvas");
    this.textCanvas = textCanvas;
    this.textCtx = textCanvas.getContext("2d");
  }
  dispose() {
    this.textures.forEach(tex => {
      tex.dispose();
    });
  }
  setProgress(progress) {
    this.targetProgress = progress;
  }
  setAttractor(point) {
    this.uAttractor.value.set(point.x, point.y, point.z);
    this.uProgress.needsUpdate = true;
  }
  createTextCanvas(text) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    let fontSize = 30;
    ctx.font = "30px monospace";
    const textMetrics = ctx.measureText(text);
    let width = textMetrics.width;
    let height = fontSize;

    canvas.width = width;
    canvas.height = height;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";

    ctx.font = "30px monospace";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    ctx.fillStyle = "transparent";
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // ctx.fillRect(0, 0, 150, 100);
    ctx.fillStyle = "white";
    ctx.fillText(text, width / 2, fontSize / 2);
    return canvas;
  }
  generateTextures() {
    this.textures = textItems.map(text => {
      let texture = new THREE.Texture(this.createTextCanvas(text));
      texture.generateMipmaps = false;
      texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
      texture.minFilter = THREE.LinearFilter;
      texture.needsUpdate = true;
      return texture;
    });
  }
  update(delta) {
    let progressChange = lerp(
      this.uProgress.value,
      this.targetProgress+0.3,
      0.1,
      0.01
    );
    if (progressChange !== 0) {
      this.uProgress.value += progressChange;
      this.uProgress.needsUpdate = true;
    }
  }
  load() {
    this.textures = [];
  }
  init() {
    this.generateTextures();

    let startMargin = -5;

    let xMargin = 2;
    let yMargin = 2;
    const pointer = {
      x: startMargin * Math.random(),
      y: 0,
    };
    let planeSegments = 1;
    const viewSize = this.sceneManager.getViewSize();
    const textures = this.textures.map(tex => {
      let width = (tex.image.width / window.innerWidth) * viewSize.width;
      let height = (tex.image.height / window.innerHeight) * viewSize.height;
      return {
        width,
        height,
        tex: tex,
        geometry: new THREE.PlaneBufferGeometry(
          width,
          height,
          planeSegments,
          planeSegments
        )
      };
    });
    let maxLength = Math.sqrt(
      (viewSize.width + 10) * (viewSize.width + 10) +
        (viewSize.height + 10) * (viewSize.height + 10)
    );
    let uMaxLength = new THREE.Uniform(maxLength);

    while (pointer.y < viewSize.height) {
      let height = textures[0].height;
      //while (pointer.x < viewSize.width) {
        let texIndex = Math.floor(Math.random() * textures.length);

        const texture = textures[texIndex];
        let width = texture.width;
        const geometry = texture.geometry;
        let color = Math.random() * 0.6 + 0.2;
        const material = new THREE.ShaderMaterial({
          fragmentShader,
          vertexShader,
          transparent: true,
          uniforms: {
            uMap: new THREE.Uniform(texture.tex),
            uAttractor: this.uAttractor,
            uProgress: this.uProgress,
            uMaxLength: uMaxLength,
            uPosition: new THREE.Uniform(
              new THREE.Vector2(
                -viewSize.width/2+width/2,//-viewSize.width / 2 + width / 2 + pointer.x,
                -viewSize.height / 2 + height / 2 + pointer.y
              )
            )
          }
        });
        const mesh = new THREE.Mesh(geometry, material);
        // mesh.position.x = -viewSize.width / 2 + width / 2 + pointer.x;
        // mesh.position.y = -viewSize.height / 2 + height / 2 + pointer.y;
        //pointer.x += width + xMargin;

        this.sceneManager.scene.add(mesh);
      //}
      pointer.y += height + yMargin;
      pointer.x = startMargin * Math.random();
    }
  }
}

// color: new THREE.Color(color, color, color),
//     map: texture.tex

const vertexShader = `
    uniform vec3 uAttractor;
    uniform float uProgress;
    uniform vec2 uPosition;
    uniform float uMaxLength;


    varying vec2 vUv;
    void main(){
        vec3 pos = vec3(position.xyz) ;
        pos.xy += uPosition;
        float di = distance(pos,uAttractor) / uMaxLength ;
        float progress = smoothstep(di, 1., uProgress);
        pos = mix(pos, uAttractor, progress);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos,1.);
        vUv = uv;
    }
`;
const fragmentShader = `
uniform sampler2D uMap;
varying vec2 vUv;
    void main(){
        vec3 color = vec3(1.);
        color = texture2D(uMap, vUv).rgb;
        color = vec3(texture2D(uMap, vUv).rgb);
        gl_FragColor = texture2D(uMap, vUv);
    }
`;
