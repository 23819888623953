import "./styles.css";
import * as THREE from "three";
import { PackedPlanes } from "./PackedPlanes.js";
import { TouchEvents } from "./touchEvents";

export class App {
  constructor() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: true
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);

    document.body.append(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    this.camera.position.z = 50;
    this.scene = new THREE.Scene();

    this.clock = new THREE.Clock();

    this.packedPlanes = new PackedPlanes(this);
    this.disposed = false;

    this.tick = this.tick.bind(this);
    this.onResize = this.onResize.bind(this);
    this.init = this.init.bind(this);
    this.loader = new Loader();
    this.touchEvents = new TouchEvents({
      onDown: this.onCursorDown.bind(this),
      onUp: this.onCursorUp.bind(this),
      onMove: this.onMouseMove.bind(this)
    });
    this.loadAssets().then(this.init);
  }
  loadAssets() {
    return new Promise((resolve, reject) => {
      // const manager = new THREE.LoadingManager(resolve);

      // this.packedPlanes.load(manager);
      resolve();
    });
  }
  createSphere() {
    const geometry = new THREE.CircleGeometry(1, 5);
    const material = new THREE.MeshBasicMaterial({ color: 0xaaaafa });
    const mesh = new THREE.Mesh(geometry, material);
    const viewSize = this.getViewSize();
    mesh.position.x = -viewSize.width / 2 + 2;
    mesh.position.y = -viewSize.height / 2 + 2;
    mesh.position.z += 0.1;
    this.scene.add(mesh);
  }
  init() {
    // this.createSphere();
    // this.createPack();
    this.packedPlanes.init();

    window.addEventListener("resize", this.onResize);
    this.touchEvents.init();
    this.tick();
  }
  getViewSize() {
    const fovInRadians = (this.camera.fov * Math.PI) / 180;
    const height = Math.abs(
      this.camera.position.z * Math.tan(fovInRadians / 2) * 2
    );

    return { width: height * this.camera.aspect, height };
  }
  dispose() {
    this.disposed = true;
    this.touchEvents.dispose();
    this.packedPlanes.dispose();
    window.removeEventListener("resize", this.onResize);
    this.scene.children.forEach(child => {
      if (child.material) child.material.dispose();
      if (child.geometry) child.geometry.dispose();
    });
  }
  onCursorDown() {
    this.packedPlanes.setProgress(0.5);
  }
  onCursorUp() {
    this.packedPlanes.setProgress(0);
  }
  onMouseMove(x, y) {
    if (this.packedPlanes.uProgress.value === 0) return;
    const viewSize = this.getViewSize();
    let mouse = {
      x: (x / window.innerWidth - 0.5) * viewSize.width,
      y: (1 - y / window.innerHeight - 0.5) * viewSize.height,
      z: 0
    };
    this.packedPlanes.setAttractor(mouse);
  }
  update() {
    this.packedPlanes.update();
  }
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  tick() {
    if (this.disposed) return;
    this.render();
    this.update();

    requestAnimationFrame(this.tick);
  }
  onResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }
}

class Loader {
  constructor() {
    this.items = [];
    this.loaded = [];
  }
  begin(name) {
    this.items.push(name);
  }
  end(name) {
    this.loaded.push(name);
    if (this.loaded.length === this.items.length) {
      this.onComplete();
    }
  }
  onComplete() {}
}
