export class TouchEvents {
  constructor({ onMove, onDown, onUp }) {
    this.onMove = onMove;
    this.onDown = onDown;
    this.onUp = onUp;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }
  init() {
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("mousedown", this.onMouseDown);
    window.addEventListener("mouseup", this.onMouseUp);
  }
  dispose() {
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mousedown", this.onMouseDown);
    window.removeEventListener("mouseup", this.onMouseUp);
  }
  onMouseMove(ev) {
    if (this.onMove) this.onMove(ev.clientX, ev.clientY);
  }
  onMouseUp() {
    if (this.onUp) this.onUp();
  }
  onMouseDown() {
    if (this.onDown) this.onDown();
  }
}
